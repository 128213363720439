<template>
    <st-page
        :title="$t('DOCUMENT_SERIES_ALLOCATION.LIST.TITLE')"
    >
        <template #toolbar>
            <DocumentSeriesAllocationListToolbar @add="add" @details="details"/>
        </template>
        <div v-if="initLoading">
            <DocumentSeriesAllocationListFilter ref="documentSeriesAllocationListFilter" >
                <DocumentSeriesAllocationListTable @view="view" @update="update" @edit="edit">
                </DocumentSeriesAllocationListTable>
            </DocumentSeriesAllocationListFilter>
        </div>
        <SelectOrganisationModal
            ref="selectOrganisationModal"
            @organisationSelected="organisationSelected"
        ></SelectOrganisationModal>
        <ManageDocumentSeriesAllocationModal
            ref="documentSeriesAllocationModal"
            :selectedDocumentSeries="selectedDocumentSeries"
            :editMode="editMode"
            @update="update"
        ></ManageDocumentSeriesAllocationModal>
        <DocumentSeriesDetailsModal
            ref="documentSeriesDetailsModal"
        ></DocumentSeriesDetailsModal>
    </st-page>
</template>
<script>
import DocumentSeriesAllocationListFilter from '@/modules/document-series-allocation/components/DocumentSeriesAllocationListFilter';
import DocumentSeriesAllocationListTable from '@/modules/document-series-allocation/components/DocumentSeriesAllocationListTable';
import DocumentSeriesAllocationListToolbar from '@/modules/document-series-allocation/components/DocumentSeriesAllocationListToolbar';
import ManageDocumentSeriesAllocationModal from '@/modules/document-series-allocation/components/ManageDocumentSeriesAllocationModal.vue';
import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';
import DocumentSeriesDetailsModal from '@/modules/document-series-allocation/components/document-series-details/DocumentSeriesDetailsModal.vue';
import Roles from '@/security/roles';
import { mapGetters } from "vuex";

export default {
    name: "DocumentSeriesAllocationList",
    components: {
        DocumentSeriesAllocationListFilter,
        DocumentSeriesAllocationListTable,
        DocumentSeriesAllocationListToolbar,
        ManageDocumentSeriesAllocationModal,
        SelectOrganisationModal,
        DocumentSeriesDetailsModal
    },
    data() {
        return {
            initLoading: false,
            editMode: false,
            selectedDocumentSeries: {}
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            organisation: 'administrator/institution',
            currentUser: 'auth/currentUser',
            downloadAccessToken: "documentSeriesAllocation/list/downloadAccessToken",
        }),
        isSuperAdmin() {
            return this.currentUser.user_type_name === Roles.values['super_admin']
        }
    },
    methods: {
        async organisationSelected() {
            this.$refs.selectOrganisationModal.hide();
            this.initLoading = true;
        },
        add() {
            this.editMode = false;
            this.$refs.documentSeriesAllocationModal.show();
        },
        details() {
            this.$refs.documentSeriesDetailsModal.show();
        },
        view(id) {
            window.open(`${window.VUE_APP_API_URL}/files/${id}?token=${this.downloadAccessToken}`, '_blank');
        },
        update() {
            this.$refs.documentSeriesAllocationListFilter.refresh();
        },
        edit(doc) {
            this.selectedDocumentSeries = doc;
            this.editMode = true;
            this.$refs.documentSeriesAllocationModal.show();
        },
    },
    mounted() {
       if (this.isSuperAdmin) {
            this.initLoading = false;
            this.$refs.selectOrganisationModal.show();
        } else {
            this.initLoading = true;
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('shared/setModuleName', null);
        next();
    }
};
</script>
<style scoped>

</style>


