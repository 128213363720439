import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import DateField from '@/shared/fields/date-field';
import { DocumentSeriesAllocationUserField } from '@/modules/document-series-allocation/document-series-allocation-user-field';
import { ActiveDocumentSeriesField } from '@/modules/document-series-allocation/active-document-series-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`DOCUMENT_SERIES_ALLOCATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DOCUMENT_SERIES_ALLOCATION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    allocated_series: new StringField('allocated_series', label('allocated_series'), {
        required: true,
    }, { placeholder: placeholder('allocated_series') }),
    created_by: new StringField('created_by', label('created_by')),
    created_by_filter: DocumentSeriesAllocationUserField.relationToOne(
        'created_by',
        label('created_by'),
        {},
        { placeholder: placeholder('created_by') },
        [],
        { asyncData: false }
    ),
    created_date: new DateField('created_date', label('created_date')),
    type_name: new StringField('type_name', label('type_name'), {}, { placeholder: placeholder('type_name') }),
    type_id: ActiveDocumentSeriesField.relationToOne(
        'type_id',
        label('type_name'),
        { autocompleteRequired: true },
        { placeholder: placeholder('type_name'), width: 'md' },
        [],
        { asyncData: false }
    ),
    issued_date: new DateField('issued_date', label('issued_date')),
    allocation_recipient_id: new StringField('allocation_recipient_id', label('allocation_recipient_id'), {
        required: true,
    }, { placeholder: placeholder('allocation_recipient_id') }),
    tax_value: new StringField('tax_value', label('tax_value')),
    availability_period_months: new StringField('availability_period_months', label('availability_period_months')),
    execution_duration: new StringField('execution_duration', label('execution_duration')),
    execution_start_date: new DateField('execution_start_date', label('execution_start_date')),
    extension_date: new DateField('extension_date', label('extension_date')),
    investition_value: new StringField('investition_value', label('investition_value')),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit_id'),
        { autocompleteRequired: false },
        { placeholder: placeholder('territorial_administrative_unit_id')},
    ),
    identification_number: new StringField('identification_number', label('identification_number')),

    // these ae redundant but kept for backward compatibility
    address: new StringField('address', label('address')),
    locality_name: new StringField('locality_name', label('locality')),

};

export class DocumentSeriesAllocationModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
